import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

export const createInstance = lang => {
  const instance = i18next
    .createInstance({
      lng: lang,
      fallbackLng: 'en',
      resources: {
        de: {
          translations: require('./locales/de/translation.json'),
        },
        en: {
          translations: require('./locales/en/translation.json'),
        },
      },
      ns: ['translations'],
      defaultNS: 'translations',
      returnObjects: true,
      debug: process.env.NODE_ENV === 'development',
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      react: {
        wait: true,
        useSuspense: false,
      },
    })
    .use(initReactI18next)

  instance.init()
  return instance
}
