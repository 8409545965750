exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-de-js": () => import("./../../../src/pages/about.de.js" /* webpackChunkName: "component---src-pages-about-de-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-chalets-de-js": () => import("./../../../src/pages/chalets.de.js" /* webpackChunkName: "component---src-pages-chalets-de-js" */),
  "component---src-pages-chalets-en-js": () => import("./../../../src/pages/chalets.en.js" /* webpackChunkName: "component---src-pages-chalets-en-js" */),
  "component---src-pages-destinations-de-js": () => import("./../../../src/pages/destinations.de.js" /* webpackChunkName: "component---src-pages-destinations-de-js" */),
  "component---src-pages-destinations-en-js": () => import("./../../../src/pages/destinations.en.js" /* webpackChunkName: "component---src-pages-destinations-en-js" */),
  "component---src-pages-experiences-bucket-list-hikes-de-js": () => import("./../../../src/pages/experiences/bucket-list-hikes.de.js" /* webpackChunkName: "component---src-pages-experiences-bucket-list-hikes-de-js" */),
  "component---src-pages-experiences-bucket-list-hikes-en-js": () => import("./../../../src/pages/experiences/bucket-list-hikes.en.js" /* webpackChunkName: "component---src-pages-experiences-bucket-list-hikes-en-js" */),
  "component---src-pages-experiences-de-js": () => import("./../../../src/pages/experiences.de.js" /* webpackChunkName: "component---src-pages-experiences-de-js" */),
  "component---src-pages-experiences-en-js": () => import("./../../../src/pages/experiences.en.js" /* webpackChunkName: "component---src-pages-experiences-en-js" */),
  "component---src-pages-experiences-instagrammable-places-de-js": () => import("./../../../src/pages/experiences/instagrammable-places.de.js" /* webpackChunkName: "component---src-pages-experiences-instagrammable-places-de-js" */),
  "component---src-pages-experiences-instagrammable-places-en-js": () => import("./../../../src/pages/experiences/instagrammable-places.en.js" /* webpackChunkName: "component---src-pages-experiences-instagrammable-places-en-js" */),
  "component---src-pages-experiences-luxury-moments-de-js": () => import("./../../../src/pages/experiences/luxury-moments.de.js" /* webpackChunkName: "component---src-pages-experiences-luxury-moments-de-js" */),
  "component---src-pages-experiences-luxury-moments-en-js": () => import("./../../../src/pages/experiences/luxury-moments.en.js" /* webpackChunkName: "component---src-pages-experiences-luxury-moments-en-js" */),
  "component---src-pages-experiences-spectacular-journeys-de-js": () => import("./../../../src/pages/experiences/spectacular-journeys.de.js" /* webpackChunkName: "component---src-pages-experiences-spectacular-journeys-de-js" */),
  "component---src-pages-experiences-spectacular-journeys-en-js": () => import("./../../../src/pages/experiences/spectacular-journeys.en.js" /* webpackChunkName: "component---src-pages-experiences-spectacular-journeys-en-js" */),
  "component---src-pages-experiences-swiss-craftmanship-de-js": () => import("./../../../src/pages/experiences/swiss-craftmanship.de.js" /* webpackChunkName: "component---src-pages-experiences-swiss-craftmanship-de-js" */),
  "component---src-pages-experiences-swiss-craftmanship-en-js": () => import("./../../../src/pages/experiences/swiss-craftmanship.en.js" /* webpackChunkName: "component---src-pages-experiences-swiss-craftmanship-en-js" */),
  "component---src-pages-experiences-world-records-de-js": () => import("./../../../src/pages/experiences/world-records.de.js" /* webpackChunkName: "component---src-pages-experiences-world-records-de-js" */),
  "component---src-pages-experiences-world-records-en-js": () => import("./../../../src/pages/experiences/world-records.en.js" /* webpackChunkName: "component---src-pages-experiences-world-records-en-js" */),
  "component---src-pages-imprint-de-js": () => import("./../../../src/pages/imprint.de.js" /* webpackChunkName: "component---src-pages-imprint-de-js" */),
  "component---src-pages-imprint-en-js": () => import("./../../../src/pages/imprint.en.js" /* webpackChunkName: "component---src-pages-imprint-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-itineraries-de-js": () => import("./../../../src/pages/itineraries.de.js" /* webpackChunkName: "component---src-pages-itineraries-de-js" */),
  "component---src-pages-itineraries-en-js": () => import("./../../../src/pages/itineraries.en.js" /* webpackChunkName: "component---src-pages-itineraries-en-js" */),
  "component---src-pages-itineraries-overview-de-js": () => import("./../../../src/pages/itineraries/overview.de.js" /* webpackChunkName: "component---src-pages-itineraries-overview-de-js" */),
  "component---src-pages-itineraries-overview-en-js": () => import("./../../../src/pages/itineraries/overview.en.js" /* webpackChunkName: "component---src-pages-itineraries-overview-en-js" */),
  "component---src-pages-privacy-statement-de-js": () => import("./../../../src/pages/privacy-statement.de.js" /* webpackChunkName: "component---src-pages-privacy-statement-de-js" */),
  "component---src-pages-privacy-statement-en-js": () => import("./../../../src/pages/privacy-statement.en.js" /* webpackChunkName: "component---src-pages-privacy-statement-en-js" */),
  "component---src-pages-ski-de-js": () => import("./../../../src/pages/ski.de.js" /* webpackChunkName: "component---src-pages-ski-de-js" */),
  "component---src-pages-ski-en-js": () => import("./../../../src/pages/ski.en.js" /* webpackChunkName: "component---src-pages-ski-en-js" */),
  "component---src-pages-terms-and-conditions-de-js": () => import("./../../../src/pages/terms-and-conditions.de.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-de-js" */),
  "component---src-pages-terms-and-conditions-en-js": () => import("./../../../src/pages/terms-and-conditions.en.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-en-js" */),
  "component---src-pages-wellness-de-js": () => import("./../../../src/pages/wellness.de.js" /* webpackChunkName: "component---src-pages-wellness-de-js" */),
  "component---src-pages-wellness-en-js": () => import("./../../../src/pages/wellness.en.js" /* webpackChunkName: "component---src-pages-wellness-en-js" */),
  "component---src-templates-destination-js": () => import("./../../../src/templates/destination.js" /* webpackChunkName: "component---src-templates-destination-js" */),
  "component---src-templates-itinerary-type-js": () => import("./../../../src/templates/itineraryType.js" /* webpackChunkName: "component---src-templates-itinerary-type-js" */)
}

