import { createStore } from 'redux'

const defaultState = {
  modal: {
    component: null,
    props: undefined,
  },
  mastheadHeight: 110,
}

const reducer = (state = defaultState, action) => {
  const { type, payload } = action
  switch (type) {
    case 'set masthead height':
      return { ...state, mastheadHeight: payload }
    case 'open modal':
      return { ...state, modal: payload }
    case 'close modal':
      return { ...state, modal: defaultState.modal }

    default:
      return state
  }
}

// preloadedState will be passed in by the plugin
const store = preloadedState => {
  return createStore(reducer, preloadedState)
}

export default store