import React from 'react'
import { I18nextProvider } from 'react-i18next'

import { createInstance } from 'i18n/config'

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props
  const { langKey } = pageContext
  return (
    <I18nextProvider i18n={createInstance(langKey)}>{element}</I18nextProvider>
  )
}
